import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import BlogGrid from '../components/BlogGrid';
import SEO from '../components/SEO';

const ns = `blog-category-archive`;

const BlogCategory = ({
	data: {
		category, page, posts,
	}, location,
}) => {
	const blogPage = page?.acfBlogCategoryArchivePage;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }-${ category.slug }`]: true,
	});

	if (!category.count > 0) {
		return (
			<div className={rootClassnames}>
				<section className={`${ ns }-hero`}>
					<div className={`container container--offset`}>
						<p>
							No blog posts found. Add posts to your WordPress site and they&apos;ll
							appear here!
						</p>
					</div>
				</section>
			</div>
		);
	}

	return (
		<div className={rootClassnames}>
			<SEO title={'Envoy Insights'} page={'Blog'} />
			<section className={`${ ns }-hero`}>
				<div className={`container container--offset`}>
					<h1 className={`${ ns }__title`}>{blogPage?.title}</h1>
					<p className={`${ ns }__description`}>{blogPage?.description}</p>
				</div>
			</section>

			<section className={`${ ns }-content`}>
				<div className={`container container--offset`}>
					<BlogGrid items={posts?.nodes} />
				</div>
			</section>

		</div>
	);
};

export default BlogCategory;

export const pageQuery = graphql`
	query WordPressBlogCategory (
		$id:String!, $name:String!
	) {
			category: wpCategory(id: { eq:$id }) {
				count
				description
				id
				name
				slug
				uri
			}
			page: wpPage(title: {eq: $name}){
				title
				id
				slug
				acfBlogCategoryArchivePage{
					title
					description
				}
			}
			posts: allWpPost(filter: {categories: {nodes: {elemMatch: {name: {eq:$name}}}}}, sort: {fields: date, order: DESC}
    ) {
				nodes {
					acfBlog{
						blogTeaserImage{
							fieldGroupName
							desktopTeaserImage{
								altText
								sourceUrl
								srcSet
							}
						}
					}
					date(formatString: "MMMM DD, YYYY")
					excerpt
					slug
					tags{
						nodes{
							name
						}
					}
					categories{
						nodes{
							name
							slug
							uri
						}
					}
					title
					uri
				}
			}
		}
`;
