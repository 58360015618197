/* Helper functions */
import React from 'react';

/**
 * isExternalUrl - used to check for external links
*/

export const isExternalUrl = url => {
	let isExternal = false;

	if (url && (url.includes('//') || url.match(/((^(mailto|tel|sms|mms):)|www.)/) || url.includes('#'))) {
		isExternal = true;
	}

	return isExternal;
};

export const setMetaImage = (featuredImage, seo) => {
	let metaImage = null;
	if (seo && seo.metaImage) {
		metaImage = seo.metaImage.sourceUrl;
	} else if (featuredImage) {
		metaImage = featuredImage.sourceUrl;
	}

	return metaImage;
};

export const slugify = text => {
	return text.toString().toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(/[^\w-]+/g, '') // Remove all non-word chars
		.replace(/--+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
};

export const getVideoIframe = url => {
	const pattern = /(\/\/.+\/)(.+v=)?([^#&?]*)($|\?.+)/;
	const matches = url.match(pattern);

	if (!matches) return false;

	const videoId = matches[3]; // Video ID is 3rd capturing group.
	let iframeSrc;
	let type;

	if (url.indexOf('youtu') !== -1) {
		type = `youtube`;
		iframeSrc = `https://www.youtube.com/embed/${ videoId }`;
	} else if (url.indexOf('vimeo') !== -1) {
		type = `vimeo`;
		iframeSrc = `https://player.vimeo.com/video/${ videoId }`;
	} else {
		return false;
	}

	return (
		<div className={`iframe-container`}>
			<iframe
				src={iframeSrc}
				title={`${ type }-video`}
				width={640}
				height={360}
				frameBorder={`0`}
				allowFullScreen
			/>
		</div>
	);
};
