import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';
import Link from '../Link';
import Image from '../Image';

import Reveal from '../Reveal';

const ns = `case-studies`;

const CaseStudies = props => {
	const {items, heading} = props;
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const renderStudies = () => {
		if (!items || items?.length < 1) {
			return null;
		}

		return items.map(item => {
			if (!item) {
				return null;
			}

			const {
				title, copy, image, link,
			} = item;

			return (
				<li className={`${ ns }__list-item`} key={title}>
					<Reveal>
						<article>
							<div className={`${ ns }__image`}>
								<Link to={link}>
									<Image dataMedia {...image} />
								</Link>

							</div>

							<div className={`${ ns }__content`}>
								{title && (
									<div className={`${ ns }__title`}>
										{title}
									</div>
								)}
								{copy && (
									<div className={`${ ns }__copy`}>
										{copy}
									</div>
								)}

							</div>
						</article>
					</Reveal>
				</li>

			);
		});
	};

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__container container container--offset`}>
				<div className={`${ ns }__section left`}>
					{heading && (
						<h4 className={`${ ns }__heading`}>
							<Reveal>
								{heading}
							</Reveal>
						</h4>
					)}
				</div>
				<div className={`${ ns }__section right`}>
					<ul className={`${ ns }__list`}>
						{renderStudies()}
					</ul>

				</div>
			</div>

		</div>
	);
};

export default CaseStudies;
