import React from 'react';
import classNames from 'classnames';

import RenderStaticHTML from '../RenderStaticHTML';

const ns = 'copy-block';

const CopyBlock = ({
	theme,
	eyebrow,
	title,
	titleAs = 'h1',
	titleClasses = '',
	description,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ theme }`]: theme,
	});

	return (
		<div className={rootClassnames}>
			{eyebrow && (
				<RenderStaticHTML
					as={'p'}
					className={`${ ns }__eyebrow eyebrow`}
					html={eyebrow}
				/>
			)}
			{(typeof title === 'string') && (
				<RenderStaticHTML
					as={titleAs}
					className={`${ ns }__title ${ titleClasses }`}
					html={title}
				/>
			)}
			{description && (
				<RenderStaticHTML
					className={`${ ns }__description`}
					html={description}
				/>
			)}
		</div>
	);
};

export default CopyBlock;
