import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Image from '../Image';
import Reveal from '../Reveal';
import CopyBlock from '../CopyBlock';
import RenderStaticHTML from '../RenderStaticHTML';

const ns = `locations`;

const Locations = ({ heading, items = [] }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	if (!items?.length) return null;

	return (
		<section className={rootClassnames}>
			<div className={`container container--offset`}>
				<Reveal>
					{heading && (
						<div className={`${ ns }__heading`}>
							{/* eslint-disable-next-line */}
							<CopyBlock {...heading} />
						</div>
					)}
					<ul>
						{items.map(({ title, description, image }) => {
							return (
								<li key={uniqueId('envoy')}>
									{image && (
										<div className={`${ ns }__media`}>
											<Image dataMedia {...image} />
										</div>
									)}
									{title && (
										<p>{title}</p>
									)}
									{description && (
										<RenderStaticHTML
											as={`address`}
											html={description}
										/>
									)}
								</li>
							);
						})}
					</ul>
				</Reveal>
			</div>
		</section>
	);
};

export default Locations;
