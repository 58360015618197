import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import PageIntro from '../components/PageIntro';
import PageMask from '../components/PageMask';
import ImageCollage from '../components/ImageCollage';
import ServicesContent from '../components/ServicesContent';
import SEO from '../components/SEO';

const ns = `page-services`;

const Services = ({ data }) => {
	const {
		servicesOverviewJson: {
			pageIntro,
			content,
		},
		allServicesJson: {
			services,
		},
	} = data;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<SEO title={'Services'} page={'services'} />
			<PageMask>
				<PageIntro
					variant={'services'}
					content={{
						heading: {
							theme: 'dark',
							titleAs: 'h1',
							titleClasses: 'h1-med',
							...pageIntro.heading,
						},
					}}
				>
					<ImageCollage images={pageIntro.imageCollage.items} />
				</PageIntro>
				<ServicesContent
					heading={{
						theme: 'light',
						titleAs: 'h3',
						...content.heading,
					}}
					items={services}
				/>
			</PageMask>
		</div>
	);
};

export default Services;

export const pageHomeQuery = graphql`
	query SERVICES_OVERVIEW_QUERY {
		servicesOverviewJson {
			pageIntro {
				heading {
					title
					eyebrow
					description
				}
				imageCollage {
					items {
						alt_text
						main_image
						optimizedImage
					}
				}
			}
			content {
				heading {
					title
					description
				}
			}
		}
		allServicesJson(sort: { fields: order }) {
			services: nodes {
				title
				url
				image: featuredImage {
					altText
					sourceUrl
					optimizedImage
				}
			}
		}
	}
`;
