import React from 'react';
import classNames from 'classnames';

import CopyBlock from '../CopyBlock';
import ThreeUpGrid from '../ThreeUpGrid';
import NumberedList from '../NumberedList';
import Reveal from '../Reveal';

const ns = `featured-services`;

const FeaturedServices = ({ heading, numberedList, threeUpGrid }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<section className={rootClassnames}>
			<div className={`container container--offset`}>
				<div className={`${ ns }__heading`}>
					<div>
						<Reveal>
							{/* eslint-disable */}
							<CopyBlock {...heading} />
						</Reveal>
					</div>
					<div>
						<Reveal>
							{/* eslint-disable */}
							<NumberedList {...numberedList} />
						</Reveal>
					</div>
				</div>
				<Reveal>
					{/* eslint-disable */}
					<ThreeUpGrid {...threeUpGrid} />
				</Reveal>
			</div>
		</section>
	);
};

export default FeaturedServices;
