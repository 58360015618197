import React from 'react';
import classNames from 'classnames';

import CopyBlock from '../CopyBlock';
import DragSlider from '../DragSlider';
import Reveal from '../Reveal';

const ns = `featured-gallery`;

const FeaturedGallery = ({heading, items}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	if (!items?.length) return null;

	return (
		<section className={rootClassnames}>
			<div className={`container container--offset`}>
				{heading && (
					<Reveal>
						<div className={`${ ns }__heading`}>
							{/* eslint-disable-next-line */}
							<CopyBlock titleAs={`h4`} theme={`dark`} {...heading} />
						</div>
					</Reveal>
				)}
				{items?.length && (
					<Reveal>
						<DragSlider items={items} />
					</Reveal>
				)}
			</div>
		</section>
	);
};

export default FeaturedGallery;
