import React from 'react';
import classNames from 'classnames';

import Link from '../Link';
import Reveal from '../Reveal';

import { slugify } from '../../util';

const ns = `job-board`;

const JobBoard = ({ items = [] }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const departmentsMarkup = items?.reduce((acc, cur) => {
		const { id: dptId, name, jobs } = cur;

		if (jobs?.length) {
			const newJobs = jobs.concat({
				id: `${dptId}_3241112`,
				absolute_url: 'https://weareenvoy.com/careers/?gh_jid=3241112#3241112',
				title: 'Don\'t see a role you are seeking?',
				location: {
					name: 'Irvine, San Diego or Chicago',
				},
			});

			const jobsMarkup = newJobs.map((job, i) => {
				const { id: jobId, title, location } = job;
				return (
					<li key={jobId}>
						{i === 0 ? (
							<div>
								<p>Open Roles</p>
							</div>
						) : (
						<div></div>
						)}

						<div>
							<Link to={`/careers/${slugify(title)}`}>{title}</Link>
						</div>
						<div>
							<p>{location.name}</p>
						</div>
					</li>
				);
			});

			const component = (
				<li key={dptId}>
					<h3 className={`${ns}__department-name h3-reg`}>{name}</h3>
					<ul className={`${ns}__jobs`}>{jobsMarkup}</ul>
				</li>
			);

			acc.push(component)
		}

		return acc;
	}, []);

	const contentMarkup = departmentsMarkup?.length ? (
		departmentsMarkup
	) : (
		<h2 className={`${ns}__department-empty`}>
			There are no job postings at this time.
		</h2>
	);

	return (
		<section className={rootClassnames}>
			<div className={`container container--offset`}>
				<Reveal>
					<ul className={`${ns}__departments`}>{contentMarkup}</ul>
				</Reveal>
			</div>
		</section>
	);
};

export default JobBoard;
