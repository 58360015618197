import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
	description,
	lang,
	meta,
	title,
	image,
	page,
}) {
	const { site, wp } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						siteUrl
						image
					}
				}
				wp {
					generalSettings {
						title
						description
					}
				}
			}
		`
	);

	const siteTitle = site.siteMetadata.title || wp.generalSettings.title;
	const siteTagline = site.siteMetadata.description || wp.generalSettings.description;
	const defaultTitle = siteTagline ? `${ siteTitle } | ${ siteTagline } ` : siteTitle;

	const metaTitle = title || siteTitle;
	const metaDescription = description ? description.replace(/(<([^>]+)>)/ig, '') : site.siteMetadata.description;
	const metaImage = site.siteMetadata.image || image;
	const metaUrl = site.siteMetadata.siteUrl;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			defaultTitle={defaultTitle}
			titleTemplate={`%s | ${ siteTitle }`}
			bodyAttributes={
				{
					'data-page': page || title || site.siteMetadata.title,
				}
			}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:url`,
					content: metaUrl,
				},
				{
					property: `og:title`,
					content: metaTitle,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:image`,
					content: metaImage,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `twitter:card`,
					content: `summary`,
				},
				{
					property: `twitter:title`,
					content: metaTitle,
				},
				{
					property: `twitter:description`,
					content: metaDescription,
				},
				{
					property: `twitter:image`,
					content: metaImage,
				},
			].concat(meta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

export default SEO;
