import React from 'react';
import classNames from 'classnames';

import CopyBlock from '../CopyBlock';
import TwoUpGrid from '../TwoUpGrid';
import Reveal from '../Reveal';

const ns = `services-content`;

const ServicesContent = ({ heading, items }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	if (!items?.length) return null;

	return (
		<section className={rootClassnames}>
			<div className={`container container--offset`}>
				<div className={`${ ns }__heading`}>
					<Reveal>
						{/* eslint-disable-next-line */}
						<CopyBlock {...heading} />
					</Reveal>
				</div>
				<Reveal>
					<TwoUpGrid items={items} />
				</Reveal>
			</div>
		</section>
	);
};

export default ServicesContent;
