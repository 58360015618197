import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Reveal from '../Reveal';

const ns = `capabilities`;

const Capabilities = ({ items = [] }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	if (!items.length) return null;

	return (
		<section className={rootClassnames}>
			<div className={`container container--offset`}>
				<Reveal>
					<ul className={`${ ns }__list`}>
						{items.map(({title, list = []}, index) => {
							return (
								<li key={uniqueId('envoy')}>
									<div>
										{index === 0 && (<p>Capabilities</p>)}
									</div>
									<div>
										{title && (
											<p>{title}</p>
										)}
									</div>
									<div>
										{list?.length && (
											<ul>
												{list.map(listItem => {
													return (
														<li key={uniqueId('envoy')}>{listItem}</li>
													);
												})}
											</ul>
										)}
									</div>
								</li>
							);
						})}
					</ul>
				</Reveal>
			</div>
		</section>
	);
};

export default Capabilities;
