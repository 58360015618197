import React from 'react';
import classNames from 'classnames';

import { getVideoIframe } from '../../util';

const ns = `video-block`;

const VideoBlock = ({
	backgroundColor,
	youtubevimeoLink,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ backgroundColor }`]: backgroundColor,
	});

	return (
		<section className={`${ rootClassnames }`}>
			<div className={`${ ns }__container`}>
				<div className={`${ ns }__video`}>
					{getVideoIframe(youtubevimeoLink)}
				</div>
			</div>
		</section>
	);
};

export default VideoBlock;
