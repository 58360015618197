/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import { validate as validateEmail } from 'email-validator';
import parse from 'html-react-parser';
import Image from '../components/Image';
import RenderStaticHTML from '../components/RenderStaticHTML';
import BlogGrid from '../components/BlogGrid';
import PageMask from '../components/PageMask';
import Reveal from '../components/Reveal';

// We're using Gutenberg so we need the block styles
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/theme.css';

import SEO from '../components/SEO';

const ns = `blog-post`;

const BlogPostTemplate = ({ data: { post, posts } }) => {
	const [isSuccess, setIsSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState(``);

	const [email, setEmail] = useState('');

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const handleSubmit = async e => {
		const portalId = process.env.HUBSPOT_PORTAL_ID;
		const formGuid = process.env.HUBSPOT_FORM_ID;

		e.preventDefault();

		if (!email) return;

		if (!validateEmail(email)) {
			setErrorMessage('Please enter a valid email address.');
			return;
		}

		try {
			await fetch(
				`https://api.hsforms.com/submissions/v3/integration/submit/${ portalId }/${ formGuid }`,
				{
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						portalId,
						formGuid,
						fields: [
							{
								name: 'email',
								value: email,
							},
						],
					}),
				}
			);

			setErrorMessage('');
			setIsSuccess(true);
		} catch (error) {
			setErrorMessage(
				'An unexpected error occurred. Please try again in a few minutes.'
			);
			setIsSuccess(false);
		}
	};

	// eslint-disable-next-line consistent-return
	const renderBlogHTML = (component, index) => {
		// eslint-disable-next-line default-case
		switch (component?.fieldGroupName) {
		case 'Post_Acfblog_BlogPageLayout_BlogTextComponents':
			return (
				<div className={`${ ns }__text-component`}>
					{component?.textComponentSubtitle && (
						<p className={`${ ns }__text-component__title`}>
							{component.textComponentSubtitle}
						</p>
					)}
					{component?.textComponentParagraphs && (
						<RenderStaticHTML
							key={`blog-component-${ index }`}
							className={`${ ns }__text-component__text`}
							html={component.textComponentParagraphs}
						/>
					)}
				</div>
			);
		case 'Post_Acfblog_BlogPageLayout_Image':
			return (
				<div className={`${ ns }__image-component`}>
					{component && (
						<Image
							dataMedia
							displayCaption
							{...component}
						/>
					)}
				</div>
			);
		case 'Post_Acfblog_BlogPageLayout_Quote':
			return (
				<div className={`${ ns }__quote-component`}>
					{component?.quoteTextarea && (
						<RenderStaticHTML
							key={`blog-component-${ index }`}
							className={`${ ns }__quote-component__text`}
							html={component.quoteTextarea}
						/>
					)}
					{component?.quoteAuthor && (
						<p className={`${ ns }__quote-component__author`}>
							{component.quoteAuthor}
						</p>
					)}
				</div>
			);
		}
	};

	return (
		<div className={rootClassnames}>
			<SEO title={post.title} description={post.excerpt} />

			<PageMask>
				<Reveal>
					<article itemScope itemType={'http://schema.org/Article'}>
						<header className={`${ ns }__header`}>
							<div className={`container container--offset`}>
								{post?.title && (
									<h1 className={`${ ns }__title`}>{parse(post.title)}</h1>
								)}
								{post?.date && (
									<p className={`${ ns }__post-date`}>{post.date}</p>
								)}
							</div>
						</header>

						<main className={`${ ns }__content`}>
							<div className={`container container--offset`}>
								<div className={`${ ns }__image-component`}>
									{post?.acfBlog?.blogHeroImage && (
										<Image
											dataMedia
											displayCaption
											{...post.acfBlog.blogHeroImage}
										/>
									)}
								</div>
								{(post.acfBlog.blogPageLayout || []).map((component, index) => {
									return (
										// eslint-disable-next-line react/no-array-index-key
										<div key={`bc-${ index }-${ component?.fieldGroupName }`}>
											{renderBlogHTML(component, index)}
										</div>
									);
								})}
							</div>
						</main>

						<footer>
							<div className={`container container--offset`}>
								<div className={`${ ns }__email-form`}>
									{errorMessage && (
										<p className={`${ ns }__email-form__error`}>{errorMessage}</p>
									)}
									{!isSuccess ? (
										<>
											<h2 className={`${ ns }__email-form__title`}>Stay in the know</h2>
											<form onSubmit={handleSubmit}>
												<div className={`${ ns }__email-form__email-field`}>
													<input
														className={`${ ns }__email-form__email-field-input`}
														name={'email'}
														type={'email'}
														value={email}
														placeholder={`Email Address`}
														onChange={e => { return setEmail(e.target.value); }}
													/>
													<input
														className={`${ ns }__email-form__submit`}
														type={'submit'}
														value={''}
													/>
												</div>
												<p>
													Sign up with your email address to receive updates and
													insights on the latest in design, technology, business
													and culture.
												</p>
											</form>
										</>
									) : (
										<>
											<h2 className={`${ ns }__email-form__title`}>You're In!</h2>
											<p>Thank you for subscribing.</p>
										</>
									)}
								</div>
							</div>
						</footer>
					</article>

					<section className={`${ ns }__related-insights`}>
						<div className={`container container--offset`}>
							{posts?.nodes?.length > 0 && (
								<BlogGrid items={posts?.nodes} isRelated />
							)}
						</div>
					</section>
				</Reveal>
			</PageMask>
		</div>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $category: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      slug
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          id
          slug
          name
          uri
        }
      }
      featuredImage {
        node {
          altText
        }
      }
      acfBlog {
        fieldGroupName
        blogAuthor {
          firstName
          lastName
        }
        blogPageLayout {
          ... on WpPost_Acfblog_BlogPageLayout_BlogTextComponents {
            fieldGroupName
            textComponentParagraphs
            textComponentSubtitle
          }
          ... on WpPost_Acfblog_BlogPageLayout_Image {
            fieldGroupName
						desktopImage {
							altText
							caption
							sourceUrl
							srcSet
						}
						tabletImage {
							altText
							caption
							sourceUrl
							srcSet
						}
						mobileImage {
							altText
							caption
							sourceUrl
							srcSet
						}
          }
          ... on WpPost_Acfblog_BlogPageLayout_Quote {
            fieldGroupName
            quoteAuthor
            quoteTextarea
          }
        }
        blogTeaserImage {
          fieldGroupName
					desktopImage: desktopTeaserImage {
						altText
						sourceUrl
						srcSet
					}
					tabletImage: tabletTeaserImage {
						altText
						sourceUrl
						srcSet
					}
					mobileImage: mobileTeaserImage {
						altText
						sourceUrl
						srcSet
					}
        }
        blogHeroImage {
          fieldGroupName
					desktopImage: heroDesktopImage {
						altText
						caption
						sourceUrl
						srcSet
					}
					tabletImage: heroTabletImage {
						altText
						caption
						sourceUrl
						srcSet
					}
					mobileImage: heroMobileImage {
						altText
						caption
						sourceUrl
						srcSet
					}
        }
      }
    }

    posts: allWpPost(
      filter: {
        id: { ne: $id }
        categories: { nodes: { elemMatch: { name: { eq: $category } } } }
      }
      sort: { fields: date, order: DESC }
      limit: 2
    ) {
      nodes {
        acfBlog {
          blogTeaserImage {
            fieldGroupName
            desktopImage: desktopTeaserImage {
              altText
              sourceUrl
              srcSet
            }
						tabletImage: tabletTeaserImage {
              altText
              sourceUrl
              srcSet
            }
						mobileImage: mobileTeaserImage {
              altText
              sourceUrl
              srcSet
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        excerpt
        slug
        tags {
          nodes {
            name
          }
        }
        categories {
          nodes {
            name
            slug
            uri
          }
        }
        title
        uri
      }
    }
  }
`;
