import React, { useRef } from 'react';
import classNames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
// import { debounce } from 'lodash';

import Header from '../Header';
import Footer from '../Footer';
// import LoadingScreen from '../../components/LoadingScreen';

const nsBase = 'layout';
const ns = `${ nsBase }-default`;

const DefaultLayout = ({
	children,
	location,
}) => {
	const layoutRef = useRef(null);

	const data = useStaticQuery(graphql`
		query GET_GLOBALS {
			globalJson {
				header {
					primaryNav {
						label
						url
					}
				}
			}
		}
	`);

	const {
		globalJson: {
			header,
		},
	} = data;

	// May have to do somethign with setting a height on the body to prevent
	// futher page jump when page transitions
	// const handleResize = () => {
	// 	const layoutEl = layoutRef.current;
	// 	const bodyEl = document.getElementsByTagName('body')[0];

	// 	bodyEl.style.height = `${ layoutEl.offsetHeight }px`;
	// };

	// useEffect(() => {
	// 	// for initial page load
	// 	handleResize();

	// 	window.addEventListener('resize', debounce(handleResize, 150), { passive: true });

	// 	return () => {
	// 		window.removeEventListener('resize', handleResize);
	// 	};
	// }, []);

	const rootClassnames = classNames({
		[`${ nsBase } ${ ns }`]: true,
	});

	// parse pathname into a page slug
	// (used for header) navigation styling
	const getPageName = path => {
		const pathComponents = path.match(/([^/]+)/g);

		if (pathComponents) {
			return `${ pathComponents.join('-') }`;
		}

		return `home`;
	};

	return (
		<>
			<Helmet
				bodyAttributes={{
					'data-page': getPageName(location.pathname),
				}}
			/>
			<div ref={layoutRef} className={rootClassnames}>
				<Header
					primaryNav={header?.primaryNav}
					pathname={location.pathname}
				/>
				<main>
					{children}
				</main>
				<Footer />
				{/* {location.pathname === '/' && (
					<LoadingScreen event={'heroLoaded'} />
				)}  */}
			</div>
		</>
	);
};

export default DefaultLayout;
