import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import PageIntro from '../components/PageIntro';
import PageMask from '../components/PageMask';
import Locations from '../components/Locations';
import ThreeUpGrid from '../components/ThreeUpGrid';
import SEO from '../components/SEO';

const ns = `page-contact`;

const Contact = ({ data }) => {
	const {
		contactJson: {
			pageIntro,
			locations,
		},
	} = data;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<SEO title={'Contact'} page={'contact'} />
			<PageMask>
				<PageIntro
					content={{
						heading: {
							theme: 'dark',
							titleAs: 'h1',
							titleClasses: 'h1-med',
							...pageIntro?.heading,
						},
					}}
				>
					<ThreeUpGrid
						theme={'dark'}
						variant={'bullets'}
						items={pageIntro?.threeUpGrid?.items}
					/>
				</PageIntro>
				<Locations
					items={locations?.items}
				/>
			</PageMask>
		</div>
	);
};

export default Contact;

export const pageContactQuery = graphql`
	query CONTACT_QUERY {
		contactJson {
			pageIntro {
				heading {
					title
				}
				threeUpGrid {
					items {
						title
					}
				}
			}
			locations {
				items {
					title
					description
					image {
						sourceUrl
						altText
					}
				}
			}
		}
	}
`;
