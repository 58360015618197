import React from 'react';
import classNames from 'classnames';

import Image from '../Image';
import CopyBlock from '../CopyBlock';

const ns = `image-stack`;

const ImageStack = ({ images = [], copy }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	if (!images?.length) return null;

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__images`}>
				{images[0] && (
					<div className={`${ ns }__image`}>
						<div>
							<Image dataMedia {...images[0]} />
						</div>
					</div>
				)}
				{images[1] && (
					<div className={`${ ns }__image`}>
						<div>
							<Image dataMedia {...images[1]} />
						</div>
					</div>
				)}
			</div>
			<div className={`${ ns }__copy`}>
				{/* eslint-disable-next-line */}
				<CopyBlock {...copy} />
			</div>
		</div>
	);
};

export default ImageStack;
