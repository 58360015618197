import gsap from 'gsap';
import CustomEase from './easing/CustomEase.min';

gsap.registerPlugin(CustomEase);

CustomEase.create('custom', 'M0,0 C0.2,0 0.5,0 0.5,0.5 0.5,1 0.8,1 1,1');

let prevScrollY = 0;

export const animationExit = () => {
	prevScrollY = window.scrollY;

	const html = document.getElementsByTagName('html')[0];
	const stickyHeader = document.querySelector('.site-header__menu-bar-sticky');

	const tl = gsap.timeline();

	// if (stickyHeader.classList.contains("site-header__menu-bar-sticky")) {
	// 	stickyHeader.classList.remove("site-header__menu-bar-sticky");
	// }

	tl
		.to(html, {
			overflow: 'hidden',
			duration: 0,
		});
};

export const animationEntry = (
	entry,
	direction,
	node
) => {
	// console.log('entry', entry);
	// console.log('direction', direction);
	// console.log('entry triggered. node:', node);

	window.scrollTo(0, prevScrollY);

	const html = document.getElementsByTagName('html')[0];
	const pageMasekEl = node.querySelector('.page-mask');

	const tl = gsap.timeline({
		onComplete: () => {
			window.scrollTo(0, 0);
		},
	});

	tl
		.to(pageMasekEl, {
			height: '100vh',
			width: '100vw',
			y: '-100%',
			duration: 0,
		})
		.to(node, {
			y: '0',
			duration: '1',
			ease: 'expo.inOut',
			force3D: 0,
		})
		.to(pageMasekEl, {
			y: '0',
			duration: '1',
			ease: 'expo.inOut',
			force3D: 0,
		}, '-=1')
		.to(pageMasekEl, {
			clearProps: 'height,width',
			duration: 0,
		})
		.to(html, {
			clearProps: 'overflow',
			duration: 0,
		});
};
