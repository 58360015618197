import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

const ns = `image-collage`;

const ImageCollage = ({ images }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const imagesContainer = useRef(null);

	/**
	 * Translate ImageCollage to the left on scroll
	 *
	 * @param {*} container
	 */
	const horizontalParallax = container => {
		const imgsContainer = container;
		const throttle = (type, name, ctx) => {
			const obj = ctx || window;
			let running = false;

			const func = () => {
				if (running) { return; }
				running = true;
				requestAnimationFrame(() => {
					obj.dispatchEvent(new CustomEvent(name));
					running = false;
				});
			};

			obj.addEventListener(type, func);
		};

		throttle('scroll', 'optimizedScroll');

		window.addEventListener('optimizedScroll', () => {
			const offset = (window.pageYOffset / 40);
			imgsContainer.style.transform = `translateX(-${ offset }%)`;
		});
	};

	useEffect(() => {
		if (!images) return null;

		horizontalParallax(imagesContainer.current);

		return () => {};
	});

	if (!images) return null;

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__images`}>
				<ul ref={imagesContainer} className={`${ ns }__items`}>
					{images.map(image => {
						const {
							alt_text: altText,
							main_image: mainImage,
						} = image;

						return (
							<li key={uniqueId('envoy')} className={`${ ns }__item`}>
								<div>
									<img
										data-media
										src={mainImage}
										alt={altText}
									/>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default ImageCollage;
