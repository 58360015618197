/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
import React from 'react';
import { Link } from 'gatsby';
import parse from 'html-react-parser';
import classNames from 'classnames';

import Image from '../Image';
import Button from '../Button';

const ns = `blog-grid`;

const BlogGrid = ({ items = [], isCategory, isRelated }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--category`]: isCategory,
		[`${ ns }--related`]: isRelated,
	});

	if (!items.length) return null;

	return (
		<section className={rootClassnames}>
			{!isCategory && !isRelated && (
				<h2 className={`${ ns }__title`}>(All Insights)</h2>
			)}
			{isRelated && (
				<div className={`${ ns }__header`}>
					<h2 className={`${ ns }__title`}>Related Insights</h2>
					<Button type={'button'} href={`/blog`} aria-label={`All Insights`}>
						All Insights
					</Button>
				</div>
			)}
			<div className={`post-list`}>
				{items.map(({
					title, categories, uri, acfBlog,
				}, index) => {
					return (
						<Link
							// eslint-disable-next-line react/no-array-index-key
							key={`blog-${ index }-${ title }`}
							to={`/blog/${ categories.nodes[0]?.slug }${ uri }`}
							itemProp={`url`}
							className={`post-list-item`}
							itemScope
							itemType={`http://schema.org/Article`}
						>
							{categories?.nodes?.[0]?.name && categories?.nodes?.[0]?.name !== `Uncategorized` && (
								<div className={`post-list-item__category`}>
									{categories.nodes[0].name}
								</div>
							)}
							<div className={`post-list-item__image`}>
								<Image
									dataMedia
									{...acfBlog.blogTeaserImage}
									sourceUrl={`/assets/blog/blog-placeholder.jpg`}
								/>
							</div>
							<header>
								<h3 className={'post-list-item__title'}>{parse(title)}</h3>
							</header>
						</Link>
					);
				})}
			</div>
		</section>
	);
};

export default BlogGrid;
