import React from 'react';
import classNames from 'classnames';

import Reveal from '../Reveal';
import RevealOnLoad from '../RevealOnLoad';
import CopyBlock from '../CopyBlock';

const ns = `page-intro`;

const PageIntro = ({
	content,
	children,
	variant,
	animationOnLoad = true,
}) => {
	const {
		heading,
	} = content;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ variant }`]: variant,
	});

	// conditionally render onload or regular Reveal
	const DynamicReveal = animationOnLoad ? RevealOnLoad : Reveal;

	return (
		<section className={rootClassnames}>
			<div className={`container container--offset`}>
				{/* eslint-disable-next-line */}
				<DynamicReveal>
					<div className={`${ ns }__heading`}>
						{/* eslint-disable-next-line */}
						<CopyBlock {...heading} />
					</div>
					{children && (
						<div className={`${ ns }__content`}>
							{children}
						</div>
					)}
				</DynamicReveal>
			</div>
		</section>
	);
};

export default PageIntro;
