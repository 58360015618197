import React from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import PageIntro from '../components/PageIntro';
import PageMask from '../components/PageMask';
import ImageStack from '../components/ImageStack';
import Capabilities from '../components/Capabilities';
import FeaturedGallery from '../components/FeaturedGallery';
import ServicesContent from '../components/ServicesContent';
import CaseStudies from '../components/CaseStudies';

const ns = `page-service-detail`;

const ServiceDetail = ({data}) => {
	const {
		pageIntro,
		capabilities,
		featuredGallery,
		caseStudies,
		featuredEngagements,
	} = data.service;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});
	return (
		<div className={rootClassnames}>
			<PageMask>
				<PageIntro
					variant={'services'}
					content={{
						heading: {
							theme: 'dark',
							titleAs: 'h1',
							titleClasses: 'h1-med',
							...pageIntro?.heading,
						},
					}}
				>
					<ImageStack
						images={pageIntro?.imageStack?.images}
						copy={{
							titleAs: 'h3',
							...pageIntro?.imageStack?.copy,
						}}
					/>
				</PageIntro>
				{caseStudies && (
					<CaseStudies {...caseStudies} />
				)}
				<Capabilities
					items={capabilities?.items}
				/>
				<FeaturedGallery
					heading={featuredGallery?.heading}
					items={featuredGallery?.items}
				/>
				<ServicesContent
					heading={{
						titleAs: 'h2',
						titleClasses: 'h4',
						title: 'Featured Engagements',
					}}
					items={featuredEngagements?.items}
				/>
			</PageMask>
		</div>
	);
};

export default ServiceDetail;

export const serviceDetailQuery = graphql`
	# these variables are passed in via createPage.pageContext in gatsby-node.js
	query ServciePageById(
		$id: String!
	) {
		# selecting the current service by id
		service: servicesJson(id: {eq: $id}) {
			title
			pageIntro {
				heading {
					eyebrow
					title
				}
				imageStack {
					copy {
						title
						description
					}
					images {
						altText
						sourceUrl
						optimizedImage
					}
				}
			}
			featuredGallery {
				heading {
					eyebrow
					title
				}
				items {
					title
					image {
						altText
						sourceUrl
						optimizedImage
					}
				}
			}
			featuredEngagements {
				items {
					title
					description
					image {
						altText
						sourceUrl
						optimizedImage
					}
				}
			}
			caseStudies {
				heading
				items {
					title
					link
					copy
					image {
						altText
						sourceUrl
					}
				}
			}
			capabilities {
				items {
					list
					title
				}
			}
		}
	}
`;
