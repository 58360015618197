import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import gsap from 'gsap';

import Logo from '../../components/Logo';
import Link from '../../components/Link';

const ns = `site-header`;

const renderCopyRight = () => {
	return `©${ new Date().getFullYear() } Envoy`;
};

const Header = ({ primaryNav, pathname }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [menuItemHovered, setMenuItemHovered] = useState(false);
	const [goingUp, setGoingUp] = useState(false);
	const [isSticky, setIsSticky] = useState(false);
	const prevScrollY = useRef(0);
	const menu = useRef(null);
	const menuFooter = useRef(null);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--sticky`]: isSticky,
		[`${ ns }--menu-open`]: menuOpen,
		[`${ ns }--menu-item-hovered`]: menuItemHovered,
		[`${ ns }--white-background`]: pathname && pathname.includes(`/blog`),
	});

	// hides the menu-bar logo when the sticky nav comes into view

	const logoContainerClassnames = classNames({
		[`${ ns }__logo--hidden`]: isSticky,
	});

	const toggleMenu = () => {
		setIsSticky(false);

		if (window.innerWidth < 768) {
			setMenuOpen(!menuOpen);
		}
	};

	const closeMenu = () => {
		setIsSticky(false);

		if (window.innerWidth < 768 && menuOpen) {
			setMenuOpen(false);
		}

		window.scrollTo(0, 0);
	};

	const handleMenuItemHover = (e, menuHovered) => {
		setMenuItemHovered(menuHovered);
		e.currentTarget.style.opacity = menuHovered ? 1 : '';
	};

	useEffect(() => {
		if (menuOpen === null) return;

		const menuDropdown = menu.current;
		const menuFooterEl = menuFooter.current;

		const navList = menuDropdown.querySelectorAll('.site-header__menu-item a');
		const menuFooterElItems = menuFooterEl.querySelectorAll('p');
		const { body } = document;

		if (menuOpen) {
			gsap.set([...navList, ...menuFooterElItems], {
				transformStyle: 'preserve-3d',
				autoRound: false,
				rotation: 0.01,
				force3D: true,
				opacity: 0,
				transition: 'opacity 0s',
				transform: 'translateY(-50px)',
			});

			// disable body scroll
			body.style.overflow = 'hidden';

			// reveal nav links
			gsap.to([...navList, ...menuFooterElItems], { // duration of the animation
				duration: 0.55,
				stagger: 0.1,
				opacity: 1,
				transform: 'translateY(0)',
				delay: 0.65, // delays the animation start
			});
		} else {
			if (window.innerWidth < 768) {
				gsap.to([...navList, ...menuFooterElItems], { // duration of the animation
					duration: 0.55,
					stagger: 0.1,
					opacity: 0,
					transform: 'translateY(-50px)',
					delay: 0.65, // delays the animation start
				});
			}

			// enable body scroll
			body.style.removeProperty('overflow');
		}
	}, [menuOpen]);

	useEffect(() => {
		const handleStickyHeader = scrollY => {
			if ((scrollY >= 250) && goingUp) {
				return setIsSticky(true);
			}

			return setIsSticky(false);
		};

		const handleScroll = () => {
			const currentScrollY = window.scrollY;
			if (prevScrollY.current < currentScrollY && goingUp) {
				setGoingUp(false);
			}
			if (prevScrollY.current > currentScrollY && !goingUp) {
				setGoingUp(true);
			}

			prevScrollY.current = currentScrollY;
			handleStickyHeader(currentScrollY);
		};

		const handleResize = () => {
			if (window.innerWidth < 768) {
				setMenuOpen(false);
			}
		};

		window.addEventListener('scroll', handleScroll, { passive: true });
		window.addEventListener('resize', handleResize, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', handleResize);
		};
	}, [goingUp]);

	return (
		<div className={rootClassnames}>
			{/* main menu */}
			<div className={`${ ns }__menu-bar`}>
				<div className={'container-fluid'}>
					<div className={`${ ns }__container`}>
						<div className={`${ ns }__logo ${ logoContainerClassnames }`}>
							<Logo
								variant={`white`}
								onClick={() => { closeMenu(); }}
							/>
						</div>
						<div className={`${ ns }__headline`}>
							<span>Envoy</span>
							<span>A Connected Customer Experience Consultancy</span>
						</div>
						<button
							type={'button'}
							aria-label={'Main nav menu open'}
							className={`${ ns }__menu-toggle`}
							onClick={() => { toggleMenu(); }}
						>
							<div />
							<div />
						</button>
						<div className={`${ ns }__menu`} ref={menu}>
							{primaryNav.map(link => {
								// eslint-disable-next-line no-nested-ternary
								const activeLinkClass = pathname.includes(link.label.toLowerCase()) ? 'active'
									: pathname.includes(`blog`) && link.label.toLowerCase() === `insights` ? `active` : ``;
								return (
									<div
										key={link.label}
										className={`${ ns }__menu-item ${ activeLinkClass }`}
										data-to-page={link.label}
										onMouseEnter={e => { handleMenuItemHover(e, true); }}
										onMouseLeave={e => { handleMenuItemHover(e, false); }}
									>
										<Link
											to={link.url}
											variant={'underline'}
											onClick={() => { closeMenu(); }}
										>
											{link.label}
										</Link>
									</div>
								);
							})}
						</div>
						<div className={`${ ns }__menu-footer`} ref={menuFooter}>
							<p>
								A Connected Customer
								<br />
								Experience Consultancy
							</p>
							<p>{renderCopyRight()}</p>
						</div>
					</div>
				</div>
			</div>
			{/* sticky menu */}
			<div className={`${ ns }__menu-bar-sticky`}>
				<div className={`${ ns }__bg`} />
				<div className={'container-fluid'}>
					<div className={`${ ns }__container`}>
						<div className={`${ ns }__logo`}>
							<Logo variant={`white`} />
						</div>
						<div className={`${ ns }__headline`}>
							<span>Envoy</span>
							<span>Connected Customer Experience Consultancy</span>
						</div>
						<button
							type={'button'}
							aria-label={'Sticky nav menu open'}
							className={`${ ns }__menu-toggle`}
							onClick={() => { toggleMenu(); }}
						>
							<div />
							<div />
						</button>
						<div className={`${ ns }__menu-sticky`}>
							{primaryNav.map(link => {
								const activeLinkClass = pathname.includes(link.label.toLowerCase()) ? 'active' : '';
								return (
									<div
										key={link.label}
										className={`${ ns }__menu-item ${ activeLinkClass }`}
										data-to-page={link.label}
										onMouseEnter={e => { handleMenuItemHover(e, true); }}
										onMouseLeave={e => { handleMenuItemHover(e, false); }}
									>
										<Link
											to={link.url}
											variant={'underline'}
										>
											{link.label}
										</Link>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
