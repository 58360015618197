import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Reveal from '../Reveal';

const ns = `numbered-list`;

const zeroBasedCount = num => {
	return num < 10 ? `0${ num }` : num;
};

const NumberedList = ({ items }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	if (!items?.length) return null;

	return (
		<div className={rootClassnames}>
			<Reveal>
				<ul>
					{items.map((item, i) => {
						const index = i + 1;

						return (
							<li key={uniqueId('envoy')}>
								<span>
									{`${ zeroBasedCount(index) }.`}
								</span>
								{item}
							</li>
						);
					})}
				</ul>
			</Reveal>
		</div>
	);
};

export default NumberedList;
