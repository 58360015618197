import React from 'react';

const Logo = () => {
	return (
		<svg
			xmlns={'http://www.w3.org/2000/svg'}
			width={`50`}
			height={`50`}
			viewBox={'0 0 50 50'}
		>
			<g
				stroke={`none`}
				strokeWidth={`1`}
				fill={`none`}
				fillRule={`evenodd`}
			>
				<g
					transform={`translate(-60.000000, -25.000000)`}
					fill={`currentColor`}
				>
					<path
						d={`M60,75 L110,75 L110,25 L60,25 L60,75 Z M79.7368421,39.4736842 L90.2631579,39.4736842 L90.2631579,43.4105263 L83.579955,43.4105263 L83.579955,48.0315789 L88.1749067,48.0315789 L88.1749067,51.9684211 L83.579955,51.9684211 L83.579955,56.5900585 L90.2631579,56.5900585 L90.2631579,60.5263158 L79.7368421,60.5263158 L79.7368421,39.4736842 Z`} //eslint-disable-line
					/>
				</g>
			</g>
		</svg>
	);
};

export default Logo;
