import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Image from '../Image';
import Link from '../Link';
import RenderStaticHTML from '../RenderStaticHTML';

const ns = `three-up-grid`;

// Default grid item format
const TextItem = ({ item }) => {
	const {
		logo,
		title,
		description,
	} = item;

	return (
		<div className={`${ ns }__item`} key={title}>
			<div className={`${ ns }__item-content`}>
				{logo && (
					<div className={`${ ns }__item-logo`}>
						<Image {...logo} />
					</div>
				)}
				{title && (
					<RenderStaticHTML
						className={`${ ns }__item-title`}
						html={title}
					/>
				)}
				{description && (
					<RenderStaticHTML
						className={`${ ns }__item-description`}
						html={description}
					/>
				)}
			</div>
		</div>
	);
};

// Image item format
const ImageItem = ({ item }) => {
	const {
		link,
		image,
	} = item;

	return (
		<div className={`${ ns }__item`} key={link.url}>
			<Link to={link.url} className={`${ ns }__item-link`}>
				{image && (
					<div className={`${ ns }__item-image`}>
						<Image dataMedia {...image} />
					</div>
				)}
				{(link?.url && link?.label) && (
					<span href={link.url} className={`${ ns }__item-link-label`}>
						{link.label}
					</span>
				)}
			</Link>
		</div>
	);
};

const ThreeUpGrid = ({
	items,
	theme = 'light',
	variant,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ theme }`]: theme,
		[`${ ns }--${ variant }`]: variant,
	});

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__items`}>
				{items?.map(item => {
					if (variant === 'image') {
						return <ImageItem key={uniqueId('envoy')} item={item} />;
					}

					return <TextItem key={uniqueId('envoy')} item={item} />;
				})}
			</div>
		</div>
	);
};

export default ThreeUpGrid;
