import React from 'react';
import classNames from 'classnames';

import CopyBlock from '../CopyBlock';
import ThreeUpGrid from '../ThreeUpGrid';
import Reveal from '../Reveal';

const ns = `featured-engagements`;

const FeaturedEngagements = ({ heading, threeUpGrid }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<section className={rootClassnames}>
			<div className={`container container--offset`}>
				<div className={`${ ns }__heading`}>
					<Reveal>
						{/* eslint-disable-next-line */}
						<CopyBlock {...heading} />
					</Reveal>
				</div>
				<Reveal>
					{/* eslint-disable-next-line */}
					<ThreeUpGrid {...threeUpGrid} />
				</Reveal>
			</div>
		</section>
	);
};

export default FeaturedEngagements;
