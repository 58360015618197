import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';
import { Swiper, SwiperSlide } from 'swiper/react';

import Image from '../Image';

const ns = `drag-slider`;

const DragSlider = ({ items, variant }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ variant }`]: variant,
	});

	if (!items?.length) return null;

	return (
		<div className={rootClassnames}>
			<Swiper slidesPerView={`auto`} className={`${ ns }__items`}>
				{items.map(({image, title}) => {
					return (
						<SwiperSlide key={uniqueId('envoy')} className={`${ ns }__item`}>
							<div className={`${ ns }__media`}>
								<Image dataMedia {...image} />
							</div>
							{title && (
								<p className={`${ ns }__title`}>{title}</p>
							)}
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default DragSlider;
