import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import PageMask from '../components/PageMask';
import RevealOnLoad from '../components/RevealOnLoad';
import SEO from '../components/SEO';

const ns = `page-404`;

const NotFoundPage = () => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<Helmet>
				<body data-header-theme={`${ 'dark' }`} />
			</Helmet>
			<PageMask>
				<SEO title={'404: Not found'} />
				<div className={'container container--offset'}>
					<RevealOnLoad>
						<h1 className={`h1-med`}>NOT FOUND</h1>
						<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
					</RevealOnLoad>
				</div>
			</PageMask>
		</div>
	);
};

export default NotFoundPage;
