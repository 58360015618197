import React from 'react';
import classNames from 'classnames';

import ThreeUpGrid from '../ThreeUpGrid';
import Reveal from '../Reveal';

const ns = `featured-bullets`;

const FeaturedBullets = ({ items }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<section className={rootClassnames}>
			<div className={`container container--offset`}>
				<Reveal>
					<ThreeUpGrid items={items} theme={'light'} variant={'bullets'} />
				</Reveal>
			</div>
		</section>
	);
};

export default FeaturedBullets;
