import React, {useRef, useEffect, useState} from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';
import { graphql } from 'gatsby';
import RenderStaticHTML from '../components/RenderStaticHTML';
import PageMask from '../components/PageMask';
import Image from '../components/Image';
import Reveal from '../components/Reveal';
import VideoBlock from '../components/VideoBlock';

const ns = `page-case-study-detail`;

const CaseStudyDetail = ({data}) => {
	const {
		attributes,
		pageDetails,
	} = data.caseStudy;
	const imageList = useRef();
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		setLoaded(true);
		return () => { setLoaded(false); };
	}, []);

	const {
		title, images, subtitle, services, description, featureVideoLink,
	} = attributes;

	const style = {
		backgroundColor: pageDetails.backgroundColor,
	};

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const renderImages = () => {
		if (!images || images?.length < 1) {
			return null;
		}

		return images.map((item, index) => {
			const {size, image} = item;
			const sizeClass = size && size?.toLowerCase()?.replace(' ', '-');
			if (index < 3) {
				return (
					<div key={uniqueId('envoy-case-study')} className={`${ ns }__image ${ sizeClass }`}>
						<Image {...image} />
					</div>
				);
			}
			return (
				<div key={uniqueId('envoy-case-study')} className={`${ ns }__image ${ sizeClass }`}>
					<Reveal>
						<Image {...image} />
					</Reveal>
				</div>
			);
		});
	};

	return (
		<div className={rootClassnames} style={style}>
			<PageMask>
				<div className={`container container--offset`}>
					<div className={`${ ns }__content`}>
						<div className={`${ ns }__content-section left`}>
							{title && (
								<h1 className={`${ ns }__title`}>
									<Reveal>
										{title}
									</Reveal>

								</h1>
							)}
							{subtitle && (
								<div className={`${ ns }__subtitle`}>
									<Reveal>
										{subtitle}
									</Reveal>
								</div>
							)}

						</div>
						<div className={`${ ns }__content-section right`}>
							{description && (
								<Reveal>
									<RenderStaticHTML className={`${ ns }__description`} html={description} />
								</Reveal>

							)}
							{services && (
								<Reveal>
									<RenderStaticHTML className={`${ ns }__services`} html={services} />
								</Reveal>
							)}

						</div>

					</div>
					<div className={`${ ns }__media ${ loaded ? 'fadeIn' : '' }`}>
						{ featureVideoLink && (
							<div className={`${ ns }__video-section`}>
								<VideoBlock youtubevimeoLink={featureVideoLink} />
							</div>
						)}
						<div className={`${ ns }__image-section`} ref={imageList}>
							{renderImages()}
						</div>
					</div>
				</div>
			</PageMask>
		</div>
	);
};

export default CaseStudyDetail;

export const caseStudyDetailQuery = graphql`
	# these variables are passed in via createPage.pageContext in gatsby-node.js
	query CaseStudyPageById($id: String!) {
		caseStudy: wpCaseStudy(id: { eq: $id }) {
			attributes: caseStudyAttributes {
				title
				subtitle
				description
				services
				featureVideoLink
				images {
					size
					image {
						altText
						sourceUrl
						srcSet
						uri
					}
				}
			}
			pageDetails: acfPageDetails {
				backgroundColor
				seo {
					title
					metaDescription
					metaImage {
						sourceUrl
					}
				}
			}
		}
	}
`;
