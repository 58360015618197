import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import PageIntro from '../components/PageIntro';
import PageMask from '../components/PageMask';
import JobBoard from '../components/JobBoard';
import Locations from '../components/Locations';
import DragSlider from '../components/DragSlider';
import FeaturedBullets from '../components/FeaturedBullets';
import SEO from '../components/SEO';
import CopyBlock from '../components/CopyBlock';

const ns = `page-careers`;

const Careers = ({ data }) => {
	const {
		careersJson: {
			pageIntro,
			featuredBullets,
			locations,
		},
		allGreenhouseDepartment,
	} = data;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const { departments } = allGreenhouseDepartment || {};

	return (
		<div className={rootClassnames}>
			<SEO title={'Careers'} page={'careers'} />
			<PageMask>
				<PageIntro
					variant={'careers'}
					content={{
						heading: {
							theme: 'dark',
							titleAs: 'h1',
							titleClasses: 'h1-med',
							...pageIntro?.heading,
						},
					}}
				>
					<DragSlider
						variant={'staggered'}
						items={pageIntro?.dragSlider.items}
					/>
				</PageIntro>
				<JobBoard items={departments} />
				<FeaturedBullets
					items={featuredBullets?.items}
				/>
				<Locations
					heading={{
						theme: 'light',
						titleAs: 'h3',
						...locations?.heading,
					}}
					items={locations?.items}
				/>
			</PageMask>
		</div>
	);
};

export default Careers;

export const pageCareersQuery = graphql`
	query CAREERS_QUERY {
		careersJson {
			pageIntro {
				heading {
					title
				}
				dragSlider {
					items {
						title
						image {
							sourceUrl
							altText
							optimizedImage
						}
					}
				}
			}
			featuredBullets {
				items {
					title
				}
			}
			locations {
				heading {
					title
				}
				items {
					title
					description
					image {
						sourceUrl
						altText
						optimizedImage
					}
				}
			}
		}
		allGreenhouseDepartment {
			departments: nodes {
				id
				name
				children {
					... on GreenhouseDepartment {
						name
					}
				}
				jobs {
					id
					title
					absolute_url
					location {
						name
					}
				}
			}
		}
	}
`;
