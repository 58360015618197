import React, {useRef, useEffect} from 'react';
import { gsap } from 'gsap';
import classNames from 'classnames';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import CopyBlock from '../CopyBlock';
import Reveal from '../Reveal';
import Image from '../Image';

gsap.registerPlugin(ScrollTrigger);

const ns = `featured-work`;

const FeaturedWork = ({ heading, items }) => {
	const containerRef = useRef(null);
	const itemRefs = useRef({});
	itemRefs.current = [];

	useEffect(() => {
		const containerEl = containerRef.current;

		itemRefs.current.forEach((item, index) => {
			// triggers the bgColor change
			const mediaTrigger = item.querySelector('img, video');

			const prevColor = index === 0
				? '#1E1D23'
				: itemRefs.current[index - 1].dataset.scrollcolor;
			
			ScrollTrigger.create({
				trigger: mediaTrigger,
				start: 'top center',
				toggleClass: 'active',
				onEnter: () => {
					if (index === 0) containerEl.classList.add('active');

					gsap.to(containerEl, {
						backgroundColor: item.dataset.scrollcolor,
						ease: 'power2.inOut',
						overwrite: 'auto',
					});
				},
				onLeaveBack: () => {
					if (index === 0) containerEl.classList.remove('active');

					gsap.to(containerEl, {
						backgroundColor: prevColor,
						ease: 'power2.inOut',
						overwrite: 'auto',
					});
				},
			});
		});
	}, []);

	const addToRefs = el => {
		if (el && !itemRefs.current.includes(el)) {
			itemRefs.current.push(el);
		}
	};

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<section ref={containerRef} className={rootClassnames}>
			<div className={`container container--offset`}>
				<div className={`${ ns }__heading`}>
					<Reveal>
						<CopyBlock {...heading} />
					</Reveal>
				</div>
				<ul className={`${ ns }__items`}>
					{items?.map(({
						title,
						categories,
						description,
						media,
						backgroundColor,
					}) => {
						return (
							<li
								key={title}
								className={`${ ns }__item`}
								data-scrollcolor={backgroundColor}
								ref={addToRefs}
							>
								<div className={`${ ns }__item-media`}>
									{media?.image && (
										<Image dataMedia sourceUrl={media.image} {...media} altText={title} />
									)}
									{media?.video && (
										<video
											width={`320`}
											height={`240`}
											autoPlay
											loop
											muted
											playsInline
											data-media
										>
											<source src={media.video} type={`video/mp4`} />
											Your browser does not support the video tag.
										</video>
									)}
								</div>
								<div className={`${ ns }__item-content`}>
									<div>
										<p className={`${ ns }__item-title`}>{title}</p>
									</div>
									<div>
										<p className={`${ ns }__item-description`}>{description}</p>
									</div>
									<div>
										<ul className={`${ ns }__item-categories`}>
											{categories?.map(category => {
												return (
													<li key={category}>{category}</li>
												);
											})}
										</ul>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</section>
	);
};

export default FeaturedWork;
