import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link } from 'gatsby';
import Image from '../Image';

const ns = `blog-slider`;

const BlogSlider = ({
	items,
	variant,
	setCursorIsVisible,
	setCursorIsExpanded,
	setCursorPosition,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ variant }`]: variant,
	});

	if (!items?.length) return null;

	const featured = items.filter(item => {
		return item.tags.nodes[0]?.name === 'Featured';
	});

	if (!featured?.length) return null;

	const handleMouseEnter = () => {
		setCursorIsVisible(true);
		setCursorIsExpanded(false);
	};

	const handleMouseLeave = () => {
		setCursorIsVisible(false);
		setCursorIsExpanded(false);
	};

	const handleSlideMouseEnter = () => {
		setCursorIsExpanded(true);
	};

	const handleSlideMouseLeave = () => {
		setCursorIsExpanded(false);
	};

	const handleMouseMove = event => {
		const { pageX: x, clientY: y } = event;

		setCursorPosition({ x, y: y + window.scrollY });
	};

	return (
		<section
			className={rootClassnames}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onMouseMove={event => { handleMouseMove(event); }}
		>
			<h2 className={`${ ns }__title`}>(Featured Insights)</h2>

			<Swiper
				slidesPerView={`auto`}
				spaceBetween={18}
				threshold={20}
				touchAngle={80}
				freeMode
				breakpoints={{
					768: {
						spaceBetween: 28,
					},
				}}
				className={`${ ns }__items`}
			>
				{featured.map(({
					title, categories, uri, acfBlog,
				}, index) => {
					return (
						<SwiperSlide
							key={uniqueId(`envoy`)}
							className={`${ ns }__item`}
							onMouseEnter={handleSlideMouseEnter}
							onMouseLeave={handleSlideMouseLeave}
						>
							<Link
								// eslint-disable-next-line react/no-array-index-key
								key={`blogPost-${ index }-${ title }`}
								to={`/blog/${ categories.nodes[0]?.slug }${ uri }`}
								itemProp={`url`}
								itemScope
								itemType={`http://schema.org/Article`}
							>
								<div className={`${ ns }__media`}>
									<>
										{categories?.nodes?.[0]?.name && categories?.nodes?.[0]?.name !== `Uncategorized` && (
											<div className={`${ ns }__blog-category`}>
												{categories.nodes[0].name}
											</div>
										)}
										<Image
											dataMedia
											{...acfBlog.blogTeaserImage}
											sourceUrl={`/assets/blog/blog-placeholder.jpg`}
										/>
									</>
								</div>
								{title && <p className={`${ ns }__blog-title`}>{title}</p>}
							</Link>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</section>
	);
};

export default React.memo(BlogSlider);
