import React from 'react';
import classNames from 'classnames';
import RenderStaticHTML from '../RenderStaticHTML';

const ns = `image`;

const Image = props => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const {
		altText,
		title,
		sourceUrl,
		mainImageMobile,
		tabletImage,
		laptopImage,
		desktopImage,
		mobileImage,
		widescreenImage,
		optimizedImage,
		dataMedia,
		displayCaption = false,
	} = props;

	// if no mainImageMobile return nothing
	if (
		!mainImageMobile
		&& !mobileImage
		&& !laptopImage
		&& !desktopImage
		&& !sourceUrl
	) {
		return null;
	}

	let tabletSet = null;
	let laptopSet = null;
	let desktopSet = null;
	let widescreenSet = null;
	let optimizedSet = null;

	const getSrc = src => {
		return src.sourceUrl ? src.sourceUrl : src;
	};

	if (tabletImage && tabletImage.sourceUrl) {
		tabletSet = (
			<>
				<source media={`(min-width: 768px)`} srcSet={getSrc(tabletImage)} />
				{displayCaption && tabletImage?.caption && (
					<RenderStaticHTML className={`${ ns }__caption`} html={tabletImage.caption} />
				)}
			</>
		);
	}

	if (laptopImage && laptopImage.sourceUrl) {
		laptopSet = (
			<>
				<source media={`(min-width: 992px)`} srcSet={getSrc(laptopImage)} />
				{displayCaption && laptopImage?.caption && (
					<RenderStaticHTML className={`${ ns }__caption`} html={laptopImage.caption} />
				)}
			</>
		);
	}

	if (desktopImage && desktopImage.sourceUrl) {
		desktopSet = (
			<>
				<source media={`(min-width: 1200px)`} srcSet={getSrc(desktopImage)} />
				{displayCaption && desktopImage?.caption && (
					<RenderStaticHTML className={`${ ns }__caption`} html={desktopImage.caption} />
				)}
			</>
		);
	}

	if (widescreenImage && widescreenImage.sourceUrl) {
		widescreenSet = (
			<>
				<source media={`(min-width: 1400px)`} srcSet={getSrc(widescreenImage)} />
				{displayCaption && widescreenImage?.caption && (
					<RenderStaticHTML className={`${ ns }__caption`} html={widescreenImage.caption} />
				)}
			</>
		);
	}

	if (optimizedImage) {
		optimizedSet = (
			<>
				<source srcSet={getSrc(optimizedImage)} type={'image/webp'} />
				{displayCaption && optimizedImage?.caption && (
					<RenderStaticHTML className={`${ ns }__caption`} html={optimizedImage.caption} />
				)}
			</>
		);
	}

	return (
		<picture>
			{optimizedSet}
			{widescreenSet}
			{desktopSet}
			{laptopSet}
			{tabletSet}
			<img
				className={rootClassnames}
				alt={
					altText
					|| title
					|| mainImageMobile?.altText
					|| mainImageMobile?.title
					|| mobileImage?.altText
					|| mobileImage?.title
					|| tabletImage?.altText
					|| tabletImage?.title
					|| desktopImage?.altText
					|| desktopImage?.title
				}
				src={
					getSrc(
						mainImageMobile
						|| mobileImage
						|| tabletImage
						|| desktopImage
						|| sourceUrl
					)
				}
				data-media={dataMedia}
				loading={'lazy'}
			/>
		</picture>
	);
};

export default Image;
