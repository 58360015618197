import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Waypoint } from 'react-waypoint';

import RevealOnLoad from '../RevealOnLoad';

const ns = `hero`;

const Hero = ({ heading, video }) => {
	const videoEl = useRef(null);
	const contentWrapRef = useRef(null);
	const [loaded, setLoaded] = useState(false);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	useEffect(() => {
		if (!videoEl) return;

		const media = videoEl.current;
		media.load();

		setLoaded(true); // render the hero copy
		media.play();

		// const { body } = document;
		// body.style.overflow = 'hidden';

		// media.addEventListener('loadeddata', function() {
		// 	setTimeout(() => {
		// 		const event = new Event('heroLoaded');
		// 		window.dispatchEvent(event);

		// 		setTimeout(() => {
		// 			setLoaded(true); // render the hero copy
		// 			media.play();
		// 		}, 3000); // loading screen takes ~ 1.25s
		// 	}, 300); // no specific reason for 300
		// }, false);
	}, []);

	const handleHeroFade = ({ currentPosition, event }) => {
		if (!event) return;

		const contentWrapEl = contentWrapRef.current;

		currentPosition === 'above'
			? contentWrapEl.classList.add('fade')
			: contentWrapEl.classList.remove('fade');
	};

	return (
		<section className={rootClassnames}>
			<div className={`${ ns }__fade-trigger`}>
				<Waypoint onEnter={handleHeroFade} onLeave={handleHeroFade} />
			</div>
			<div ref={contentWrapRef} className={`${ ns }__content-wrap`}>
				<video
					ref={videoEl}
					className={`${ ns }__video`}
					width={`320`}
					height={`240`}
					controls={false}
					playsInline
					muted
					loop
				>
					<source src={video.src} type={`video/mp4`} />
					Your browser does not support the video tag.
				</video>
				<div className={`${ ns }__overlay`} />
				{loaded && (
					<div className={`${ ns }__text color-white`}>
						<div className={`container-fluid container-fluid--offset`}>
							<RevealOnLoad>
								<div className={`${ ns }__text-content`}>
									<h1>{heading.title}</h1>
								</div>
							</RevealOnLoad>
						</div>
					</div>
				)}
			</div>
		</section>
	);
};

export default Hero;
