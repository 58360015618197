import React from 'react';
import classNames from 'classnames';

import SafeAnchor from '../SafeAnchor';

/*
- href prop wll create a regular a-tag but still with button classes
- disabled prop will add 'tabindex' of -1 and 'aria-disabled' of 'true' for accessibility
- variant - prop will add a modifier to classname
*/

const ns = `button`;

const Button = React.forwardRef(
	(
		{
			active,
			className,
			variant,
			type,
			as,
			...props
		},
		ref
	) => {
		// define button classes
		const rootClassnames = classNames(
			className,
			`${ ns }`,
			`${ ns }--${ variant }`,
			active && 'active'
		);

		/* eslint-disable */
	if (props.href) {
		return (
			<SafeAnchor
				{...props}
				as={as}
				ref={ref}
				className={classNames(rootClassnames, props.disabled && 'disabled', `${ ns }--link`)}
			/>
		);
	}

	if (ref) {
		props.ref = ref;
	}

	if (!as) {
		props.type = type;
	}

    const Component = as || 'button';
    return <Component {...props} className={rootClassnames} />;
    /* eslint-enable */
	}
);

Button.defaultProps = {
	variant: 'default',
	active: false,
	disabled: false,
	type: 'button',
};

export default Button;
