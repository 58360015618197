import React from 'react';
import classNames from 'classnames';
import TransitionLink from 'gatsby-plugin-transition-link';
import SafeAnchor from '../SafeAnchor';

import { isExternalUrl } from '../../util';
import { animationExit, animationEntry } from '../../util/pageTransitions';

const ns = `link`;

const Link = React.forwardRef(
	(
		{
			className,
			disabled = false,
			variant,
			to,
			href,
			swipe,
			direction = 'up',
			...props
		},
		ref
	) => {
		const rootClassnames = classNames({
			[`${ ns }`]: true,
			[`${ ns }--${ variant }`]: variant,
			[className]: className,
		});

		if (isExternalUrl(to) || isExternalUrl(href)) {
			return (
				<SafeAnchor
					{...props} // eslint-disable-line
					disabled={disabled}
					href={href || to}
					ref={ref}
					target={'_blank'}
					className={classNames(rootClassnames)}
				/>
			);
		}

		if (disabled) {
			props.tabIndex = -1; // eslint-disable-line
			props['aria-disabled'] = true; // eslint-disable-line
		}

		if (direction) {
			return (
				<TransitionLink
					{...props} // eslint-disable-line
					disabled={disabled}
					exit={{
						delay: 0.25,
						length: 1.5,
						trigger: ({ exit, node }) => {
							animationExit(exit, direction, node);
						},
					}}
					entry={{
						delay: 0.25,
						length: 1.5,
						trigger: ({ entry, node }) => {
							animationEntry(entry, direction, node);
						},
					}}
					to={to || href || '/'}
					ref={ref}
					className={rootClassnames}
					preventScrollJump
				>
					{props.children}
				</TransitionLink>
			);
		}

		return null;
	}
);

export default Link;
