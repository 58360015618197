import React from 'react';
import classNames from 'classnames';

const ns = `cursor`;

const Cursor = ({ position, isVisible, isExpanded }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--visible`]: isVisible,
		[`${ ns }--expanded`]: isExpanded,
	});

	return (
		<div className={rootClassnames} style={{ transform: `translate3d(${ position?.x || 0 }px, ${ position?.y || 0 }px, 0)` }}>
			<div className={`${ ns }__wrapper`}>
				<svg className={`${ ns }__svg ${ ns }__svg--internal`} width={'40'} height={'32'} viewBox={'0 0 40 32'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
					<path d={'M0 16H38M38 17L24 2M37 16L24 30'} stroke={'white'} strokeWidth={'3'} />
				</svg>
			</div>
		</div>
	);
};

export default Cursor;
