import React from 'react';
import classNames from 'classnames';

const ns = `page-mask`;

const PageMask = ({ children }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			{children}
		</div>
	);
};

export default PageMask;
