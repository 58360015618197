import React, { useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import CategoryFilter from '../components/CategoryFilter';
import BlogSlider from '../components/BlogSlider';
import BlogGrid from '../components/BlogGrid';
import SEO from '../components/SEO';
import PageMask from '../components/PageMask';
import Reveal from '../components/Reveal/Reveal';
import Cursor from '../components/Cursor/Cursor';

const ns = `blog-archive`;

const BlogIndex = ({
	data,
	location,
}) => {
	const { slug } = data.wpPage.slug;

	const subPageSlug = location.pathname.split('/').filter(el => {
		return !!el;
	})[1] ?? '';
	const blogPage = data?.wpPage?.acfBlogArchivePage;
	const posts = data?.allWpPost?.nodes;

	const [selectedFilters, setSelectedFilters] = useState([]);
	const [cursorIsVisible, setCursorIsVisible] = useState(false);
	const [cursorIsExpanded, setCursorIsExpanded] = useState(false);
	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

	const getFilteredPosts = () => {
		if (!selectedFilters?.length > 0 && posts?.length > 0) return posts;

		return posts.filter(
			post => {
				return post?.categories?.nodes?.[0]?.name
        && selectedFilters.includes(post.categories.nodes[0]?.name);
			}
		);
	};

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }-${ slug }`]: slug,
	});

	if (!posts.length) {
		return (
			<div className={rootClassnames}>
				<section className={`${ ns }-hero`}>
					<div className={`container container--offset`}>
						<p>
							No blog posts found.
						</p>
					</div>
				</section>
			</div>
		);
	}

	return (
		<div className={rootClassnames}>
			<SEO title={'Envoy Insights'} page={'Blog'} />

			<Cursor
				position={cursorPosition}
				isVisible={cursorIsVisible}
				isExpanded={cursorIsExpanded}
			/>

			<PageMask>
				<Reveal>
					<section className={`${ ns }-hero`}>
						<div className={`container container--offset`}>
							<h1 className={`${ ns }__title`}>{blogPage?.title || `Envoy Insights`}</h1>
							<p className={`${ ns }__description`}>{blogPage?.description || `Our thoughts, reflections and musings on the intersection of design, technology, culture and business.`}</p>
						</div>
					</section>

					<section className={`${ ns }-content`}>
						<div className={`container container--offset`}>
							<CategoryFilter
								posts={posts}
								pageslug={slug}
								subPageSlug={subPageSlug}
								initialFilterValues={location?.state?.filterValues}
								paginationVisible={posts?.length}
								selectedFilters={selectedFilters}
								setSelectedFilters={setSelectedFilters}
							/>
							{posts?.length && selectedFilters?.length === 0 && (
								<BlogSlider
									items={posts}
									setCursorIsVisible={setCursorIsVisible}
									setCursorIsExpanded={setCursorIsExpanded}
									setCursorPosition={setCursorPosition}
								/>
							)}
							<BlogGrid
								items={getFilteredPosts()}
								isCategory={selectedFilters?.length > 0}
							/>
						</div>
					</section>
				</Reveal>
			</PageMask>
		</div>
	);
};

export default BlogIndex;

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $id: String) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: 10
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        slug
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            name
            slug
          }
        }
        tags {
          nodes {
            name
          }
        }
        acfBlog {
          blogTeaserImage {
            fieldGroupName
            desktopImage: desktopTeaserImage {
              altText
              sourceUrl
              srcSet
            }
						tabletImage: tabletTeaserImage {
              altText
              sourceUrl
              srcSet
            }
						mobileImage: mobileTeaserImage {
              altText
              sourceUrl
              srcSet
            }
          }
        }
      }
    }
    wpPage(id: { eq: $id }) {
      title
      id
      slug
      acfBlogArchivePage {
        title
        description
      }
    }
  }
`;
