import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Link from '../Link';
import Image from '../Image';
import Reveal from '../Reveal';

const ns = `two-up-grid`;

const TwoUpGrid = ({
	items,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<Reveal>
				<ul className={`${ ns }__items`}>
					{items?.map(item => {
						const {
							url,
							title,
							description,
							image,
						} = item;

						return (
							<li key={uniqueId('envoy')} className={`${ ns }__item`}>
								{url ? (
									<Link to={url} className={`${ ns }__item-link`}>
										{image && (
											<div className={`${ ns }__item-image`}>
												<Image dataMedia altText={title} {...image} />
											</div>
										)}
										<div className={`${ ns }__item-text`}>
											<div>{title}</div>
										</div>
									</Link>
								) : (
									<>
										{image && (
											<div className={`${ ns }__item-image`}>
												<Image dataMedia altText={title} {...image} />
											</div>
										)}
										<div className={`${ ns }__item-text`}>
											<div className={`${ ns }__item-text-title`}>{title}</div>
											<div className={`${ ns }__item-text-description`}>{description}</div>
										</div>
									</>
								)}
							</li>
						);
					})}
				</ul>
			</Reveal>
		</div>
	);
};

export default TwoUpGrid;
