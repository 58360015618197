/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';

const ns = `category-filter`;

const useGetCategories = () => {
	const categories = useStaticQuery(graphql`
    query {
      allWpCategory {
        edges {
          node {
            id
            name
            slug
          }
        }
        nodes {
          name
          slug
          id
        }
      }
    }
  `);

	return categories.allWpCategory;
};

const CategoryFilter = props => {
	const {
		pageSlug,
		subPageSlug,
		posts,
		onChange,
		onFilterChange,
		initialFilterValues,
		paginationVisible,
		paginationTotal,
		TitleElement = 'h2',
		selectedFilters,
		setSelectedFilters,
		...rest
	} = props;

	const { edges, nodes } = useGetCategories();

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	if (!edges?.length) return null;

	const allFilters = edges;

	const updateSelectedFilters = category => {
		if (!selectedFilters.includes(category)) { return setSelectedFilters([...selectedFilters, category]); }

		// eslint-disable-next-line no-shadow
		return setSelectedFilters(selectedFilters => { return selectedFilters.filter(filter => { return filter !== category; }); });
	};

	return (
		<section className={`${ rootClassnames }`}>
			<div className={`${ rootClassnames }__list`}>
				{allFilters
					.filter(i => { return i.node.slug !== 'uncategorized'; })
					.map((category, index) => {
						return (
							<div
								key={`category-filter-${ index }-${ category }`}
								className={classNames({
									[`${ ns }__button`]: true,
									[`${ ns }__button--active`]: selectedFilters.includes(
										category?.node?.name
									),
								})}
							>
								<button
									key={`button-${ index }-${ category }`}
									className={`${ rootClassnames }__list-item`}
									onClick={() => { return updateSelectedFilters(category?.node?.name); }}
									aria-label={category?.node?.name}
								>
									{category?.node?.name}
								</button>
							</div>
						);
					})}
				{selectedFilters?.length > 0 && (
					<div
						className={classNames({
							[`${ ns }__button`]: true,
							[`${ ns }__button--clear`]: true,
						})}
					>
						<button
							className={`${ rootClassnames }__list-item`}
							onClick={() => { setSelectedFilters([]); }}
							aria-label={`Clear All Filters`}
						>
							Clear All Filters
						</button>
					</div>
				)}
			</div>
		</section>
	);
};

export default CategoryFilter;
