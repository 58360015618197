import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import SEO from '../components/SEO';
import Hero from '../components/Hero';
import PageIntro from '../components/PageIntro';
import FeaturedServices from '../components/FeaturedServices';
import FeaturedWork from '../components/FeaturedWork';
import FeaturedEngagements from '../components/FeaturedEngagements';
import ThreeUpGrid from '../components/ThreeUpGrid';
import PageMask from '../components/PageMask';

const ns = `page-home`;

const Home = ({ data }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	// Set the meta image
	// const metaImage = setMetaImage(featuredImage?.node, seo);

	const {
		homeJson: {
			hero,
			pageIntro,
			featuredServices,
			featuredWork,
			featuredEngagements,
		},
	} = data;

	return (
		<div className={rootClassnames}>
			<SEO page={`home`} />
			<PageMask>
				<Hero
					heading={hero?.heading}
					video={hero?.video}
				/>
				<PageIntro
					variant={`home`}
					animationOnLoad={false}
					content={{
						heading: {
							theme: 'dark',
							titleAs: 'h2',
							titleClasses: 'h1-med',
							...pageIntro?.heading,
						},
					}}
				>
					<ThreeUpGrid
						theme={'dark'}
						variant={'bullets'}
						items={pageIntro?.threeUpGrid?.items}
					/>
				</PageIntro>
				<FeaturedServices
					heading={{
						titleAs: 'h2',
						...featuredServices?.heading,
					}}
					numberedList={featuredServices?.numberedList}
					threeUpGrid={{
						theme: 'dark',
						variant: 'image',
						...featuredServices?.threeUpGrid,
					}}
				/>
				<FeaturedWork
					heading={{
						theme: 'dark',
						titleAs: 'h2',
						...featuredWork?.heading,
					}}
					items={featuredWork?.items}
				/>
				<FeaturedEngagements
					heading={{
						titleAs: 'h2',
						titleClasses: 'h4',
						...featuredEngagements?.heading,
					}}
					threeUpGrid={featuredEngagements?.threeUpGrid}
				/>
			</PageMask>
		</div>
	);
};

export default Home;

export const pageHomeQuery = graphql`
	query HOME_QUERY {
		homeJson {
			hero {
				heading {
					title
				}
				video {
					src
				}
			}
			pageIntro {
				heading {
					title
				}
				threeUpGrid {
					items {
						title
					}
				}
			}
			featuredServices {
				heading {
					title
					eyebrow
					description
				}
				numberedList {
					items
				}
				threeUpGrid {
					items {
						link {
							label
							url
						}
						image {
							sourceUrl
							altText
							optimizedImage
						}
					}
				}
			}
			featuredWork {
				heading {
					title
					eyebrow
				}
				items {
					title
					description
					backgroundColor
					media {
						image
						video
						optimizedImage
					}
					categories
				}
			}
			featuredEngagements {
				heading {
					title
					eyebrow
				}
				threeUpGrid {
					items {
						logo {
							title
							sourceUrl
							optimizedImage
						}
						title
						description
					}
				}
			}
		}
	}
`;
